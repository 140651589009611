import {
  AnswersSumDependency,
  LimitSumDependency,
  ListVariantDependency,
  MultiListVariantDependency,
  QuestionModel
} from 'src/api';
import {
  getObjectFromTarget,
  isAnswersSumDependency,
  isLimitSumDependency,
  isListVariantDependency,
  isListVariantDependencyNotShow,
  isListVariantDependencySecond,
  isMultiListVariantDependency,
  isObject
} from '../utils';

/**
 * Check dependency
 */
const checkDependency = ({
  isFirst,
  question,
  getAnswer
}: {
  isFirst: boolean;
  question: QuestionModel;
  getAnswer: (questionId: string) => any;
}) => {
  const dependency_base = isFirst
    ? question.dependency
    : question.dependency_second;
  const {
    questionId,
    answers,
    answers_count,
    list_variant = ''
  } = dependency_base as MultiListVariantDependency;

  const currentAnswer = getAnswer(questionId);

  const currentAnswerAsArray = Array.isArray(currentAnswer)
    ? currentAnswer
    : [];

  const validAnswer = answers.split('|');

  const getValidAnswers = () => {
    if (list_variant) {
      return list_variant
        .split('|')
        .map(
          variant => (currentAnswer as Record<string, string | number>)[variant]
        );
    }

    if (typeof currentAnswer === 'string') {
      return [currentAnswer];
    }

    return Object.keys(currentAnswer);
  };

  //limitation for count and answer
  if (answers_count && validAnswer) {
    if (
      Object.keys(getObjectFromTarget<string>(currentAnswer)).length ===
        answers_count &&
      getValidAnswers()
        .filter(Boolean)
        .map(String)
        .some(text => validAnswer.includes(text))
    ) {
      return;
    }

    return true;
  }

  if (list_variant) {
    const variantAnswers = list_variant
      .split('|')
      .map(
        listVariant => getObjectFromTarget<string>(currentAnswer)[listVariant]
      )
      .filter(Boolean);

    if (
      variantAnswers.some(answer => validAnswer.includes(answer)) ||
      (validAnswer.some(van => van.includes('<>')) &&
        variantAnswers.some(answer =>
          validAnswer.some(van => van.includes('<>') && van !== '<>' + answer)
        ))
    )
      return;

    return true;
  }

  if (
    typeof currentAnswer === 'number' &&
    validAnswer.includes(`${currentAnswer}`)
  )
    return;

  const currentAnswers =
    typeof currentAnswer === 'string'
      ? currentAnswer.split('|')
      : currentAnswerAsArray;

  if (currentAnswers.some(answer => validAnswer.includes(`${answer}`))) return;

  if (isObject(currentAnswer)) {
    const currentResult_k = Object.keys(currentAnswer);
    const currentResult_v = Object.values(currentAnswer);

    if (validAnswer.some(van => van.includes('<>'))) {
      if (
        validAnswer.some(van => van.includes('<>')) &&
        currentResult_k.some(answer =>
          validAnswer.some(van => van.includes('<>') && van === '<>' + answer)
        )
      ) {
        return true;
      } else {
        return;
      }
    }

    if (
      currentResult_k.some(answer => validAnswer.includes(`${answer}`)) ||
      currentResult_v.every(answer => validAnswer.includes(`${answer}`))
    )
      return;
  }

  return true;
};

/**
 * Use Dependency Check
 */
const useDependencyCheck = ({
  question,
  getAnswer
}: {
  question: QuestionModel;
  getAnswer: (questionId: string) => any;
}) => {
  if (
    !question ||
    (!question.dependency &&
      !question.dependency_not_show &&
      !question.dependency_second)
  )
    return;

  if (
    [isMultiListVariantDependency, isListVariantDependency].some(fn =>
      fn(question.dependency)
    ) &&
    !isLimitSumDependency(question.dependency) &&
    !isListVariantDependencySecond(question.dependency_second)
  ) {
    const skip = checkDependency({ isFirst: true, question, getAnswer });

    if (skip) {
      return true;
    }
  }

  if (isListVariantDependencySecond(question.dependency_second)) {
    const skip = checkDependency({ isFirst: true, question, getAnswer });

    if (
      question.dependency_second.type === 'and' ||
      question.dependency_second.type === undefined
    ) {
      //and - both condition will not initialize next
      if (skip) {
        return true;
      }

      const secondSkip = checkDependency({
        isFirst: false,
        question,
        getAnswer
      });

      if (secondSkip) {
        return true;
      }
    } else {
      //or - one of condition (dependency or dependency_second) will not initialize next
      if (skip) {
        //if prev dependency skip = true check dependency_second
        const skip = checkDependency({ isFirst: false, question, getAnswer });

        if (skip) {
          return true;
        }
      }
      // if prev dependency skip = false, then show message
    }
  }

  if (isListVariantDependencyNotShow(question.dependency_not_show)) {
    const {
      questionId,
      answers,
      answers_count,
      list_variant = '',
      type
    } = question.dependency_not_show as ListVariantDependency;

    const answersList = answers.split('|');

    const currentAnswer = getAnswer(questionId);

    if (type === 'all') {
      return Object.values(currentAnswer).every(value => value === answers);
    }
    else if(type === 'one_of'){ //add type to check if one of params in answerList is goal to picked variants
      for (const element of currentAnswer) {
        if(answersList.some(x=>x === element)){
          return true; 
        } 
      }
    }

    const getValidAnswers = () => {
      if (list_variant) {
        return list_variant
          .split('|')
          .map(
            variant =>
              (currentAnswer as Record<string, string | number>)[variant]
          );
      }

      if (typeof currentAnswer === 'string') {
        return [currentAnswer];
      }

      return Object.keys(currentAnswer);
    };

    //limitation for count and answer
    if (answers_count && answersList) {
      if (
        Object.keys(getObjectFromTarget<string>(currentAnswer)).length ===
          answers_count &&
        getValidAnswers()
          .filter(Boolean)
          .map(String)
          .some(text => answersList.includes(text))
      ) {
        return true;
      }

      return;
    }

    if (
      !getValidAnswers()
        .filter(Boolean)
        .map(String)
        .some(text => answersList.includes(text))
    )
      return;

    return true;
  }

  if (isLimitSumDependency(question.dependency)) {
    const {
      questionId,
      type,
      limit_sum,
      limit
    } = question.dependency as LimitSumDependency;

    const currentAnswer = getAnswer(questionId);

    let condition;

    if (!isNaN(limit_sum as number)) {
      const sum =
        Object.values(getObjectFromTarget<number | string>(currentAnswer))
          .map(Number)
          .filter(Number)
          .reduce((sum, current) => sum + current, 0) || 0;

      condition =
        type === 'more'
          ? (limit_sum as number) < sum
          : (limit_sum as number) > sum;
    }

    if (!isNaN(limit as number)) {
      const answer = ((question.dependency as unknown) as ListVariantDependency)
        .answers;
      // @ts-ignore
      const value = +currentAnswer[answer];

      condition =
        type === 'more' ? (limit as number) < value : (limit as number) > value;
    }

    if (condition) return;

    return true;
  }

  if (isAnswersSumDependency(question.dependency)) {
    const { questionId } = question.dependency as AnswersSumDependency;
    const currentAnswer = getAnswer(questionId);

    const { answers_count } = question.dependency as AnswersSumDependency;

    if (
      Object.keys(getObjectFromTarget<string>(currentAnswer)).length >
      answers_count
    ) {
      return;
    }

    return true;
  }

  return;
};

export { useDependencyCheck };

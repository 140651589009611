import { useEffect } from 'react';
import { AnswerWithComment, List} from 'src/api';
import { useStore } from 'src/context';
import {
  useAgeFilteredList,
  useDependencyListAnswers,
  useDependencyNotShow,
  useDependencyListVariantAnswersWoExt
} from '../../hooks';
import { QuestionProps } from '../props';

/**
 * <RadioQuestion /> props type
 */
type RadioQuestionProps = QuestionProps<AnswerWithComment> & {
  list: List[];
};

/**
 * <RadioQuestion /> props
 */
const useRadioQuestionProps = ({
  value,
  ...props
}: Partial<RadioQuestionProps>) => {
  const { list: filteredList } = useAgeFilteredList(props.list as List[]);
  const dependencyNotShowList = useDependencyNotShow(filteredList);
  //const list = useDependencyListAnswers(dependencyNotShowList);
  const list = (props.question?.dependency_answers_list_variant) ? useDependencyListVariantAnswersWoExt(props.question?.answers[0].list) : useDependencyListAnswers(dependencyNotShowList);
  const isBigList = list.length >= 5;

  const { changeIsQuestionAnswered } = useStore();

  useEffect(() => {
    changeIsQuestionAnswered(
      typeof value === 'string'
        ? !!value.length
        : !!value?.answer.length && !!value.comment.length
    );
  }, [value]);

  return {
    configuredList: list,
    isBigList
  };
};

export { useRadioQuestionProps };

import { ListVariant } from 'src/api';
import { useStore } from 'src/context';

/**
 * Use Dependency List Variant Answers
 */
const useDependencyListVariantAnswersWoExt = (list: any[]) => {
  const { results } = useStore();

  return list.filter(listItem => {
    if (!listItem.dependency) {
      return true;
    }

    const { answers = '', questionId, list_variant } = listItem.dependency;

    const currentResult = results[questionId];

    const answersList = answers.split('|');

    const getValidAnswers = () => {
      if (list_variant) {
        return list_variant
          .split('|')
          .map(
            variant =>
              (currentResult as Record<string, string | number>)[variant]
          );
      }

      if (typeof currentResult === 'string') {
        return [currentResult];
      }

      return Object.keys(currentResult);
    };

    return getValidAnswers()
      .filter(Boolean)
      .map(String)
      .some(text => answersList.includes(text));
  });
};

export { useDependencyListVariantAnswersWoExt };
